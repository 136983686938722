<script>
import Layout from '../layouts/main';
import { api } from '@/state/services';
import { mapState } from 'vuex';
import Stat from '@/components/widgets/stat';

export default {
  locales: {
    pt: {
      'Dashboard': 'Painel',
      'Package': 'Pacote',
      'Binary': 'Binário',
      'Graduation': 'Graduação',
      'Points': 'Pontos',
      'Binary Info': 'Informações do Binário',
      'STATUS': 'STATUS',
      'QUALIFIED': 'QUALIFICADO',
      'UNQUALIFIED': 'NÃO QUALIFICADO',
      'LEFT TEAM': 'EQUIPE ESQUERDA',
      'RIGHT TEAM': 'EQUIPE DIREITA',
      'PERCENT': 'PORCENTAGEM',
      'BONUS FORECAST': 'PREVISÃO DE BÔNUS',
      'Binary Configuration': 'Configuração do Binário',
      'Side': 'Lado',
      'Left': 'Esquerdo',
      'Right': 'Direito',
      'Change': 'Alterar',
      'Referral link': 'Link de indicação',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
    },
    es: {
      'Dashboard': 'Panel',
      'Package': 'Paquete',
      'Binary': 'Binario',
      'Graduation': 'Graduación',
      'Points': 'Puntos',
      'Binary Info': 'Información Binaria',
      'STATUS': 'ESTADO',
      'QUALIFIED': 'CALIFICADO',
      'UNQUALIFIED': 'NO CUALIFICADO',
      'LEFT TEAM': 'EQUIPO IZQUIERDA',
      'RIGHT TEAM': 'EQUIPO DERECHA',
      'PERCENT': 'POR CIENTO',
      'BONUS FORECAST': 'PREVISIÓN DE BONIFICACIÓN',
      'Binary Configuration': 'Configuración Binaria',
      'Side': 'Lado',
      'Left': 'Izquierdo',
      'Right': 'Derecho',
      'Change': 'Cambiar',
      'Referral link': 'Link de patrocineo',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
    }
  },
  components: { Layout, Stat },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
      time: {
        date: "",
        hour: "",
        zone: "",
      },

      alert: {
        binary: { type: '', message: '' },
      },

      entity: {
        name: "",
        logo: "",
      },

      total: {
        balance: {
          cash: "-- -,--",
          bonus: "-- -,--"
        },
      },

      graduation: {
        name: "-",
        points: "-.--",
      },

      statData: null,
      wallets: null,

      binaryInfo: {
        loading: true,
        errored: false,
        empty: false,
        status: '',
        left: '0',
        right: '0',
        percent: '0%',
        bonus: 'R$ 0,00',
      },

      binaryConfig: {
        options: {
          'l': 'Left',
          'r': 'Right'
        },
        side: 'l'
      },
    };
  },
  validations: {
    binary: {},
  },
  methods: {
    getTime() {
      api
        .get('time')
        .then(response => {
          if (response.data.status=='success') {
            this.time.date = response.data.date
            this.time.hour = response.data.hour
            this.time.zone = response.data.zone
          }
        })
    },
    getEntity() {
      api
        .get('entity/default')
        .then(response => {
          if (response.data.status=='success') {
            this.entity.name = response.data.name
            this.entity.logo = response.data.logo
          }
        })
    },
    getTotalBalanceCash() {
      api
        .get('wallet/balance/cash')
        .then(response => {
          if (response.data.status=='success') {
            this.total.balance.cash = response.data.balance
            this.getStatData();
          }
        })
    },
    getTotalBalanceBonus() {
      api
        .get('wallet/balance/bonus')
        .then(response => {
          if (response.data.status=='success') {
            this.total.balance.bonus = response.data.balance
            this.getStatData();
          }
        })
    },
    getStatData() {
      this.statData = [
        {
          icon: "mdi mdi-currency-usd",
          title: "Cash Back",
          value: this.total.balance.cash,
        },
        {
          icon: "bx bx-dollar",
          title: "Bônus",
          value: this.total.balance.bonus
        }
      ]
    }
  },
  mounted() {
    this.getTime()
    this.getEntity()
    this.getStatData()
    this.getTotalBalanceCash()
    this.getTotalBalanceBonus()
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Dashboard') }}</h4>
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-6 col-md-3 mb-2">
        <router-link tag="a" to="/wallet/deposit" class="btn btn-danger btn-block p-3">
          <h4 class="m-0 text-white" style="line-height: 20px;">Faça Sua<br>Doação</h4>
        </router-link>
      </div>
      <div class="col-6 col-md-3 mb-2 card-h-fix">
        <router-link tag="a" to="/auction" class="btn btn-danger btn-block p-3">
          <h4 class="m-0 text-white" style="line-height: 20px;">Leilão<br>do Bem</h4>
        </router-link>
      </div>
      <div class="col-6 col-md-3 mb-2 card-h-fix">
        <router-link tag="a" to="/invoices/create" class="btn btn-danger btn-block p-3">
          <h4 class="m-0 text-white" style="line-height: 20px;">Notas<br>Fiscais</h4>
        </router-link>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-3 col-md-6">
        <div class="card overflow-hidden">
          <div class="card-body text-center">
            <div class="avatar-xl m-auto">
              <img
                v-if="account.user.avatar"
                class="img-thumbnail rounded-circle"
                :src="account.user.avatar"
                alt=""
              />
              <img
                v-else
                class="img-thumbnail rounded-circle"
                src="@/assets/images/logo-icon.png"
                alt=""
              />
            </div>
            <h5 class="mt-3 mb-2 font-size-15 text-truncate">{{ account.user.name }}</h5>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6">
        <div class="card overflow-hidden">
          <div class="card-body text-center">
            <div class="avatar-xl m-auto">
              <img
                v-if="entity.logo"
                class="img-thumbnail rounded-circle"
                :src="entity.logo"
                alt=""
              />
              <img
                v-else
                class="img-thumbnail rounded-circle"
                src="@/assets/images/logo-icon.png"
                alt=""
              />
            </div>
            <h5 class="mt-3 mb-2 font-size-15 text-truncate">Você está ajudando!</h5>
          </div>
        </div>
      </div>
      <div class="col-xl-6">
        <div class="row">
          <div v-for="stat of statData" :key="stat.icon" class="col-md-6">
            <Stat :icon="stat.icon" :title="stat.title" :value="stat.value" />
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="mb-2">
              <i class="bx bx-link mr-1"></i> {{ t('Referral link') }}
            </h5>
            <p class="m-0 notranslate">
              https://app.influenciadorespelobem.org.br/{{ account.user.username }}
              <button class="btn btn-link p-0 ml-2" v-clipboard:copy="'https://app.influenciadorespelobem.org.br/' + account.user.username"><i class="bx bx-copy font-size-18 text-primary"></i></button>
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-lg-6 offset-lg-3 col-sm-12">
        <div class="card text-center">
          <div class="card-body">
            <div class="mb-4 rounded-circle">
              <img class="rounded-circle avatar-lg bg-soft-primary" src="@/assets/images/logo-icon.png" height="80" width="80" alt />
            </div>
            <p class="text-muted">O BINGO ONLINE solicitou a autorização do débito no valor de R$ 100,00</p>
            <h5 class="font-size-15 text-dark">
              Referente a fatura número 1000
            </h5>
            <a href="#" class="btn btn-outline-secondary mr-1">Recusar<i class="mdi mdi-close ml-1"></i></a>
            <a href="#" class="btn btn-primary">Autorizar<i class="mdi mdi-check ml-1"></i></a>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <table class="table table-nowrap m-0">
              <tbody>
                <tr>
                  <td class="border-0 p-2 align-middle">
                    <strong class="d-block mb-0">#14251</strong>
                    <span class="mb-0">Bingo Online</span>
                  </td>
                  <td class="border-0 p-2 align-middle">
                    <strong class="d-block text-weight-bold mb-0">Valor</strong>
                    <span class="mb-0">R$ 345,00</span>
                  </td>
                  <td class="border-0 p-2 align-middle">
                    <strong class="d-block text-weight-bold mb-0">Satus</strong>
                    <span class="mb-0">Pendente</span>
                  </td>
                  <td class="border-0 p-2 text-right align-middle">
                    <a href="#" class="btn btn-danger">Ver <i class="mdi mdi-eye ml-1"></i></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div> -->
  </Layout>
</template>
